import React, { FC } from 'react';

import { ReactComponent as Box } from '../static/images/box.svg';
import { ReactComponent as Cube } from '../static/images/cube.svg';
import { ReactComponent as Dollar } from '../static/images/dollar.svg';
import { ReactComponent as Flag } from '../static/images/flag.svg';
import { ReactComponent as Pulse } from '../static/images/pulse.svg';
import { ReactComponent as Target } from '../static/images/target.svg';

import { ScrollingText } from './ScrollingText/ScrollingText';
import {
  OpportunitiesBlock,
  OpportunitiesBlockProps,
} from './OpportunitiesBlock';

const OPPORTUNITIES: OpportunitiesBlockProps[] = [
  {
    icon: <Box height={32} width={32} />,
    title: 'Real-time data',
    description:
      'Our real-time endpoints are 1 block fresh for crucial metrics',
  },
  {
    icon: <Pulse height={32} width={32} />,
    title: 'Cost-efficiency',
    description:
      "Integrate Lambda quickly because everything comes out of the box, and you don't need specific engineers to build from scratch",
  },
  {
    icon: <Target height={32} width={32} />,
    title: 'Scalable architecture',
    description:
      'Lambda will help your application grow from the first users and be ready to provide quality data for thousands of users per second.',
  },
  {
    icon: <Flag height={32} width={32} />,
    title: 'Customization mode',
    description:
      'Fork existing real-time pipeline to get custom streaming. Lambda would give estimation of latency for new endpoint',
  },
  {
    icon: <Dollar height={32} width={32} />,
    title: 'Data quality',
    description:
      'Exactly once data delivery. Protocol for recalculating metrics in case of issues with RPC nodes as well as runtime changes at the source. Ability to recover data in case of deletion. Parallel data access for multiple systems.',
  },
  {
    icon: <Cube height={32} width={32} />,
    title: 'Flexible config',
    description:
      'Lambda offers a flexible platform configuration tailored to business needs. For example, you can prioritize data consistency over availability, or vice versa',
  },
];

export const Why: FC = () => {
  return (
    <section
      className="relative z-20 mb-32"
      data-id="block:advantages_cards--default"
    >
      <div className="mx-auto w-fit" id="benefits">
        <ScrollingText
          text="BENEFITS - BENEFITS - BENEFITS - BENEFITS - "
          endOffset={60}
        />
      </div>
      <div className="mt mx-auto mb-10 mt-[30px] w-fit text-[24px] tracking-[-2px] lg:text-[32px] xl:text-[50px]">
        WHY LAMBDA?
      </div>
      <div className="grid-row mx-auto grid w-full max-w-[820px] grid-cols-2 grid-rows-3  gap-x-[30px] gap-y-[30px] lg:grid-cols-3 lg:grid-rows-2 lg:gap-x-[60px]">
        {OPPORTUNITIES.map((item) => (
          <OpportunitiesBlock {...item} />
        ))}
      </div>
    </section>
  );
};
