import React, { useRef, useState } from 'react';
import SnackbarProvider from 'react-simple-snackbar';

import { Advantages } from './components/Advantages';
import { ConnectForm } from './components/ConnectForm/ConnectForm';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero/Hero';
import { Menu } from './components/Menu/Menu';
import { Rainbow } from './components/Rainbow/Rainbow';
import { SubscribeForm } from './components/SubscribeForm/SubscribeForm';
import { Why } from './components/Why';

import './App.css';

function App() {
  const ref = useRef<HTMLDivElement>(null);
  const [show] = useState(true);

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;
  //
  //   const handleScroll = () => {
  //     //code
  //     if (ref?.current?.scrollTop! > lastScrollY) {
  //       // if scroll down hide the navbar
  //       // setShow(false);
  //       console.log('hide');
  //     } else {
  //       // if scroll up show the navbar
  //       // setShow(true);
  //       console.log('show');
  //     }
  //
  //     // remember current page location to use in the next move
  //     setLastScrollY(ref?.current?.scrollTop!);
  //   };
  //
  //   ref.current?.addEventListener('scroll', handleScroll);
  //
  //   return () => {
  //     ref.current?.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <SnackbarProvider>
      <div className="section-container font-normal" ref={ref}>
        <Menu show={show} />
        <div className="section">
          <Hero />
        </div>
        <div className="section long-section pt-16" id="long">
          <Advantages />
          <Rainbow />
          <SubscribeForm />
          <Why />
          {/*<Try />*/}
          <ConnectForm />
          <Footer />
        </div>
      </div>
    </SnackbarProvider>
  );
}

export default App;
