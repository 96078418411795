import React from 'react';

import { ReactComponent as Dots } from '../../static/images/dots.svg';
import one from '../../static/images/icons/1.png';
import two from '../../static/images/icons/2.png';
import three from '../../static/images/icons/3.png';
import { ReactComponent as Four } from '../../static/images/icons/4.svg';
import five from '../../static/images/icons/5.png';
import { ReactComponent as Six } from '../../static/images/icons/6.svg';
import { ReactComponent as Seven } from '../../static/images/icons/7.svg';
import { ReactComponent as Eight } from '../../static/images/icons/8.svg';
import { ReactComponent as Nine } from '../../static/images/icons/9.svg';
import { ReactComponent as Ten } from '../../static/images/icons/10.svg';
import { ReactComponent as Eleven } from '../../static/images/icons/11.svg';
import { ReactComponent as Twelve } from '../../static/images/icons/12.svg';
import { ReactComponent as Thirteen } from '../../static/images/icons/13.svg';
import { ReactComponent as Fourteen } from '../../static/images/icons/14.svg';
import { ReactComponent as Fifteen } from '../../static/images/icons/15.svg';
import { ReactComponent as Sixteen } from '../../static/images/icons/16.svg';
import { ReactComponent as Seventeen } from '../../static/images/icons/17.svg';
import { ReactComponent as Eighteen } from '../../static/images/icons/18.svg';
import { ReactComponent as Nineteen } from '../../static/images/icons/19.svg';
import { ReactComponent as Twenty } from '../../static/images/icons/20.svg';
import { ReactComponent as TwentyOne } from '../../static/images/icons/21.svg';
import { ReactComponent as TwentyTwo } from '../../static/images/icons/22.svg';
import { ReactComponent as TwentyThree } from '../../static/images/icons/23.svg';
import { ReactComponent as TwentyFour } from '../../static/images/icons/24.svg';
import { ReactComponent as TwentyFive } from '../../static/images/icons/25.svg';
import { ReactComponent as TwentySix } from '../../static/images/icons/26.svg';
import { ReactComponent as LogoColor } from '../../static/images/logo-color.svg';
import rainbow from '../../static/images/rainbow.png';
import { ScrollingText } from '../ScrollingText/ScrollingText';

import { RainbowBlock, RainbowBlockProps } from './RainbowBlock';

import './Rainbow.css';

const BLOCKS: RainbowBlockProps[] = [
  {
    title: 'RPC nodes',
    titleColor: '#FF5151',
    description:
      'Connect to a reliable RPC provider to ensure seamless communication and data access within your application infrastructure',
    icons: [
      <img src={one} width={40} height={40} alt="provider icon" />,
      <img src={two} width={40} height={40} alt="provider icon" />,
      <img src={three} width={40} height={40} alt="provider icon" />,
      <Four height={40} width={40} />,
    ],
  },
  {
    title: 'Extract the data',
    titleColor: '#06C2DC',
    description:
      'Integrate native RPC methods with existing indexers and occasionally with custom smart contract calls to access readily available data.',
    icons: [
      <img src={five} width={40} height={40} alt="provider icon" />,
      <Six height={40} width={40} />,
      <Seven height={40} width={40} />,
    ],
  },
  {
    title: 'Data source business logic',
    titleColor: '#E39724',
    description:
      'Examine the data and business logic pertinent to a specific DeFi (Decentralized Finance) project.',
    icons: [
      <Eight height={40} width={40} />,
      <Nine height={40} width={40} />,
      <Ten height={40} width={40} />,
      <Eleven height={40} width={40} />,
      <Twelve height={40} width={40} />,
      <Thirteen height={40} width={40} />,
      <Fourteen height={40} width={40} />,
      <Fifteen height={40} width={40} />,
      <Sixteen height={40} width={40} />,
      <Seventeen height={40} width={40} />,
    ],
  },
  {
    title: 'Store data and stream through API',
    titleColor: '#B7BB05',
    description:
      'You will require a database/data warehouse with accessible data to support your application, both on the front and back ends.',
    icons: [
      <Eighteen height={40} width={40} />,
      <Nineteen height={40} width={40} />,
      <Twenty height={40} width={40} />,
    ],
  },
  {
    title: 'Transform the data',
    titleColor: '#F153E1',
    description:
      'Use orchestration tools for deep metrics with queue management for real-time transformations',
    icons: [
      <TwentyOne height={40} width={40} />,
      <TwentyTwo height={40} width={40} />,
    ],
  },
  {
    title: 'DevOps-ing',
    titleColor: '#1DAD34',
    description:
      'Ensure to monitor your data pipelines, promptly address any bugs, report issues as they arise, and scale your infrastructure to accommodate growth.',
    icons: [
      <TwentyThree height={40} width={40} />,
      <TwentyFour height={40} width={40} />,
      <TwentyFive height={40} width={40} />,
      <TwentySix height={40} width={40} />,
    ],
  },
];

export const Rainbow = () => {
  return (
    <div className="px-5 lg:px-[30px]" data-id="block:main_desc--default">
      <div className="mx-auto mt-[128px] w-fit" id="overview">
        <ScrollingText
          text="OVERVIEW - OVERVIEW - OVERVIEW - OVERVIEW - "
          endOffset={66}
        />
      </div>
      <div className="mx-auto mb-10 mt-[30px] w-[820px] max-w-full text-center text-[24px] tracking-[-2px] lg:text-[32px] xl:text-[50px]">
        LAMBDA IS COMPLEX SOLUTION FOR DATA DELIVERY
      </div>
      <section className="relative mb-10 w-full">
        <div className="relative z-10 flex flex-col justify-around gap-[30px] lg:flex-row lg:gap-0">
          {BLOCKS.map((item) => (
            <RainbowBlock {...item} key={item.title} />
          ))}
        </div>
        <div className="mx-auto my-0 pt-[230px] text-center font-bold">
          Lambda API
        </div>
        <div className="mx-auto my-0 flex justify-center pt-5">
          <LogoColor />
        </div>
        <div className="mx-auto my-0 w-[190px] pb-[56px] pt-5 text-center text-xs">
          Use API inside of your backend/frontend
        </div>

        <img
          src={rainbow}
          style={{ width: '100%', height: '100%' }}
          className="absolute top-0 h-full "
          alt="big arrow"
        />
        <Dots width={1252} height={1252} className="dots hidden xl:block" />
      </section>
    </div>
  );
};
