import React, { FC } from 'react';

import './ScrollingText.css';

interface ScrollingTextProps {
  text: string;
  direction?: 'left' | 'right';
}

export const FullwidthScrollingText: FC<ScrollingTextProps> = ({
  text,
  direction = 'left',
}) => {
  return (
    <div className="scroll-container-fullwidth bg-purple">
      <div
        className={`scroll-text-fullwidth tracking-[-2.8px text-[32px] leading-[64px] lg:text-[50px] lg:leading-[84px] xl:text-[70px] xl:leading-[90px] direction-${direction} ]`}
      >
        {text}
      </div>
    </div>
  );
};
