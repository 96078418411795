import React, { FC, useState } from 'react';

import { ReactComponent as Logo } from '../../static/images/logo.svg';
import { Button } from '../Button';

import './Menu.css';

interface MenuProps {
  show: boolean; // this prop will show / hide menu on scroll
}

export const Menu: FC<MenuProps> = ({ show }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenuClick = (id: string, isMobile = false) => {
    if (isMobile) {
      window.location.hash = id;
      setIsOpen(false);
    }
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  };

  return (
    <>
      <div
        className={`sticky top-0 z-30 bg-black ${isOpen ? 'h-full' : 'h-fit'} ${!show && 'hidden'}`}
      >
        <div className="flex w-full items-center justify-between px-5 py-2 lg:px-[30px]">
          <Logo
            onClick={() => handleMobileMenuClick('hero')}
            className="cursor-pointer"
          />
          <ul className="desktop-menu hidden h-10 items-center gap-10 text-sm text-white sm:hidden lg:flex">
            <li className="cursor-pointer">
              <span onClick={() => handleMobileMenuClick('use-case', false)}>
                USE CASE
              </span>
            </li>
            <li className="cursor-pointer">
              <span onClick={() => handleMobileMenuClick('overview', false)}>
                OVERVIEW
              </span>
            </li>
            <li className="cursor-pointer">
              <span onClick={() => handleMobileMenuClick('benefits', false)}>
                BENEFITS
              </span>
            </li>
            {/*<li className="cursor-pointer">*/}
            {/*  <a href="#test-drive">TEST DRIVE</a>*/}
            {/*</li>*/}
            <li>
              <Button
                text="GET STARTED"
                variant="transparent"
                textAppearance="dark"
                onClick={() => handleMobileMenuClick('get-started', false)}
              />
            </li>
          </ul>
          <div className="lg:hide lg:hidden">
            <div
              id="menu-icon"
              className={isOpen ? 'open' : ''}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="absolute top-[72px] h-full w-full px-5 py-[38px]">
            <ul className="uppercase">
              <li
                className="mb-10 cursor-pointer text-[32px]"
                onClick={() => handleMobileMenuClick('use-case', true)}
              >
                USE CASE
              </li>
              <li
                className="mb-10 cursor-pointer text-[32px]"
                onClick={() => handleMobileMenuClick('overview', true)}
              >
                OVERVIEW
              </li>
              <li
                className="mb-10 cursor-pointer text-[32px]"
                onClick={() => handleMobileMenuClick('benefits', true)}
              >
                BENEFITS
              </li>
              {/*<li*/}
              {/*  className="mb-10 cursor-pointer text-[32px]"*/}
              {/*  onClick={() => handleMobileMenuClick('test-drive', true)}*/}
              {/*>*/}
              {/*  TEST DRIVE*/}
              {/*</li>*/}
              <li
                className="mb-10 cursor-pointer text-[32px]"
                onClick={() => handleMobileMenuClick('get-started', true)}
              >
                Get started
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
