import React, { FC } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { pushDataEvent } from '../../helpers/analytics';
import { TextInputWithButton } from '../Inputs/TextInputWithButton';

export const SubscribeForm: FC = () => {
  const [openSnackbar] = useSnackbar({
    style: {
      zIndex: '9999',
    },
  });

  return (
    <section className="relative z-20 mb-32 px-5">
      <div className="mx-auto mb-5 w-fit text-[24px] tracking-[-0.68px] lg:text-[30px] xl:text-[34px]">
        YOUR BUSINESS
      </div>
      <div className="mb-5 w-full">
        <div className="mx-auto my-0 w-fit max-w-full">
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={async (values, { resetForm }) => {
              const result = await fetch(
                'https://hooks.slack.com/services/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',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type':
                      'application/x-www-form-urlencoded;charset=UTF-8',
                  },
                  body: JSON.stringify({
                    channel: '#lambda-feed',
                    username: 'lambda-feed-bot',
                    text: JSON.stringify(values),
                    icon_emoji: ':ghost:',
                  }),
                }
              );
              if (result.ok) {
                pushDataEvent('subscribe:success', {});
                resetForm();
                openSnackbar(
                  'Thank you! Your form has been successfully submitted.',
                  50000
                );
              } else {
                pushDataEvent('subscribe:error', {});
                openSnackbar(
                  'Oops! Something went wrong, please try again later.'
                );
              }
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            })}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <Form
                className="mx-auto w-[520px] max-w-full"
                onSubmit={handleSubmit}
              >
                <TextInputWithButton
                  value={values.email}
                  onChange={handleChange}
                  buttonText="CONNECT"
                  name="email"
                  onCLick={handleSubmit}
                  placeholder="Your Email"
                  error={errors.email}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="w-full">
        <div className="mx-auto my-0 w-[600px] max-w-full text-center text-xs">
          Lambda respects your privacy. By submitting your email, you are
          acknowledging that you have read and agree to our Privacy Policy,
          which details how we collect and use your information.
        </div>
      </div>
    </section>
  );
};
