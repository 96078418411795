import React, { ChangeEventHandler, FC } from 'react';
import { Field } from 'formik';

interface TextInputProps {
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
}

export const TextInput: FC<TextInputProps> = ({
  name,
  value,
  onChange,
  label,
  placeholder = label,
  required = false,
  error,

}) => {
  return (
    <div className="mb-[30px]">
      <div className="mb-2 text-sm">
        {label}
        {required ? <span className="text-red">*</span> : null}
      </div>
      <Field
        name={name}
        value={value}
        onChange={onChange}
        className={`input mb-2 h-[56px] w-full rounded border border-gray bg-gray px-5 py-[18px] focus:outline-0 ${error ? '!border-red' : ''}`}
        placeholder={placeholder}
      />
      <div className="text-red">{error}</div>
    </div>
  );
};
