import React, { FC } from 'react';

import { ADVANTAGES_BLOCKS } from '../constants/common';

import { ScrollingText } from './ScrollingText/ScrollingText';
import { AdvantageBlock } from './AdvantageBlock';

export const Advantages: FC = () => {
  return (
    <section data-id="block:options_cards--default">
      <div className="mx-auto w-fit" id="use-case">
        <ScrollingText
          text="USE CASES - USE CASES - USE CASES - USE CASES - "
          endOffset={69}
        />
      </div>
      <div className="mx-auto mb-10 mt-[30px] w-fit text-[24px] tracking-[-2px] lg:text-[32px] xl:text-[50px]">
        EVERYTHING YOU WANT...
      </div>
      <div className="advantages-section">
        {ADVANTAGES_BLOCKS.map((item) => (
          <AdvantageBlock {...item} />
        ))}
      </div>
    </section>
  );
};
