import React, { ChangeEventHandler, FC } from 'react';

import { Button } from '../Button';

import './Inputs.css';

interface TextInputWithButtonProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  buttonText: string;
  name: string;
  onCLick: () => void;
  placeholder: string;
  error?: string;
}

export const TextInputWithButton: FC<TextInputWithButtonProps> = ({
  value,
  onChange,
  buttonText,
  onCLick,
  placeholder,
  error,
  name,
}) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <div>
      <div
        className={`wrapper relative z-10 flex w-[600px] max-w-full rounded border border-gray bg-gray p-5 ${focused ? 'focused' : ''}`}
      >
        <input
          value={value}
          onChange={onChange}
          name={name}
          className="mr-2 min-w-[50px] flex-1 border-0 bg-gray focus:outline-0"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <Button
          text={buttonText}
          onClick={onCLick}
          variant="main"
          textAppearance="light"
        />
        <span
          className={`placeholder ${focused ? 'focused' : ''} ${value?.length > 0 ? 'valued' : ''}`}
        >
          {placeholder}
        </span>
      </div>
      <div className="mt-2 text-red">{error}</div>
    </div>
  );
};
