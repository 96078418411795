import React, { FC, ReactNode } from 'react';

export interface RainbowBlockProps {
  title: string;
  titleColor: string;
  description: string;
  icons?: ReactNode[];
}

export const RainbowBlock: FC<RainbowBlockProps> = ({
  title,
  titleColor,
  description,
  icons,
}) => {
  return (
    <div className="flex-1 px-5 lg:text-center">
      <div
        style={{ color: titleColor }}
        className="mt-[30px] text-[16px] font-bold lg:h-[68px]"
      >
        {title}
      </div>
      <div className="mt-2.5 text-xs leading-[144%] lg:mt-0 lg:min-h-[87px]">
        {description}
      </div>
      <div className="mt-5 flex flex-wrap gap-3 sm:px-0 lg:mt-[30px] lg:justify-center xl:px-4">
        {icons}
      </div>
    </div>
  );
};
