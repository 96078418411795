import React, { FC, ReactNode } from 'react';

import { Button } from './Button';

interface AdvantageBlockProps {
  title: string;
  icon: ReactNode;
  description: string;
  advantages: string[];
  href: string;
}

export const AdvantageBlock: FC<AdvantageBlockProps> = ({
  title,
  icon,
  description,
  advantages,
  href,
}) => {
  const handleClick = (href: string) => {
    window.location.hash = href;

    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
  };

  return (
    <div className="flex h-[406px] flex-col justify-between rounded bg-gray p-[30px] text-left">
      <div>
        <div className="mb-5 flex font-bold">
          {icon} <div className="ml-3">{title}</div>
        </div>
        <div className="mb-5">{description}</div>
        <ul className="list-inside list-disc">
          {advantages.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <Button
        text="GET STARTED"
        variant="transparent"
        textAppearance="light"
        onClick={() => handleClick(href)}
      />
    </div>
  );
};
