import React, { FC } from 'react';

import { ReactComponent as Logo } from '../static/images/logo.svg';

export const Footer: FC = () => {
  const year = new Date();

  return (
    <div className="mt-16 flex w-full items-center justify-between p-[30px]">
      <Logo />
      <div className="text-semi-white text-sm">
        Lambda Inc. {year.getFullYear()}
      </div>
    </div>
  );
};
