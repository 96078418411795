import React, { FC, ReactNode } from 'react';
export interface OpportunitiesBlockProps {
  title: string;
  icon: ReactNode;
  description: string;
}
export const OpportunitiesBlock: FC<OpportunitiesBlockProps> = ({
  title,
  icon,
  description,
}) => {
  return (
    <div className="flex w-full flex-col items-center gap-[14px] text-center">
      <div>{icon}</div>
      <div className="font-bold">{title}</div>
      <div className="sm:hidden lg:block">{description}</div>
    </div>
  );
};
