import React from 'react';

import { ReactComponent as ChartCircle } from '../static/images/bar-chart-circle.svg';
import { ReactComponent as CrossingLines } from '../static/images/crossing-lines.svg';
import { ReactComponent as Eye } from '../static/images/eye.svg';
import { ReactComponent as Server } from '../static/images/server.svg';

export const ADVANTAGES_BLOCKS = [
  {
    title: 'Multi chain backends',
    icon: <ChartCircle />,
    description:
      'For multi-chain apps: integrate your backend with Lambda to expand your app for new chains/protocols. You can fork any pre-build data pipeline:',
    advantages: [
      'Address indexation with event labels',
      'Liquidity',
      'Protocol parameters',
      'Risks notifications such as slashing, liquidity drops, and price fluctuations',
    ],
    href: '#get-started',
  },
  {
    title: 'DeFi dApps',
    icon: <Eye />,
    description:
      'When developing a wallet from scratch or integrating your DEX to a new L2 network, you can get a ready-to-use API out of the box, as well as write custom aggregations:',
    advantages: [
      'Transfers & events',
      'Liquidity, ticks, reserves',
      'Prices calculated on top of reserves',
      'TVLs, swap volumes, trade activities',
    ],
    href: '#get-started',
  },
  {
    title: 'LRT / LST and staking apps',
    icon: <CrossingLines />,
    description:
      'Lambda makes your staking ecosystem developer friendly to speed up integration for LRTs / LSTs. We provide APIs with real-time performance metrics and historical datasets with rewards details:',
    advantages: [
      'Real-time  validator performance streaming',
      'Staking market data by each validator - delegator',
      'Slashing events alerting',
    ],
    href: '#get-started',
  },
  {
    title: 'Off chain computation consumers',
    icon: <Server />,
    description:
      'Oracles and Bridges use Lambda as off-chain computation unit to bring the aggregated results back on-chain. So any metrics that you want to use in automated smart-contract can be fetched and calculated here:',
    advantages: ['Prices', 'Risk events', 'Liquidity movements'],
    href: '#get-started',
  },
];
