import React, { FC } from 'react';

import cube from '../../static/images/cube.webp';
import ring from '../../static/images/ring.webp';
import { FullwidthScrollingText } from '../ScrollingText/FullwidthScrollingText';
import { ScrollingText } from '../ScrollingText/ScrollingText';

import './Hero.css';

export const Hero: FC = () => {
  return (
    <div className="hero" id="hero">
      <img src={cube} className="cube" alt="3d cube" />
      <img src={ring} className="bagle" alt="3d ring" />

      <div className="z-10 mx-auto my-0 w-[1211px] max-w-full px-5 pt-[174px] text-center text-[32px] uppercase tracking-[-1.8px] lg:text-[50px] lg:tracking-[-2.8px] xl:pt-[5%] xl:text-[70px]">
        <span className="text-purple">Stream</span> cross-chain aggregated data
        to build <span className="text-purple">web3</span> app with high load
        ready infra
      </div>
      <div className="z-20 w-full px-5">
        <div className="mt-11 lg:translate-x-1/2 ">
          <ScrollingText
            text="WHAT? - WHAT? - WHAT? - WHAT? - WHAT? - WHAT?"
            endOffset={94}
          />
        </div>
        <div className="my-0 mt-2.5 w-[335px] max-w-full lg:mx-auto lg:translate-x-1/2">
          Lambda speeds up web3 app development by providing everything from the
          box data platform: streaming, history, data quality, exactly ones
          delivery, consistency, data recovery, custom data transformations
        </div>
      </div>
      <div className="absolute bottom-0 w-full uppercase">
        <FullwidthScrollingText text="• 1s latency • 1K+ open connections • 10 PBs of deep historical data • 1s latency • 1K+ open connections • 10 PBs of deep historical data" />
      </div>
    </div>
  );
};
